@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html {
    overscroll-behavior-y: contain;
}

html, body {
    background-color: #f6f6f6;
    font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

@media (prefers-color-scheme: dark) {
    html, body {
        background-color: #111827;
    }
}

/* Fixes icons being cut off inside of swiper */
#root {
    overflow: hidden;
}

.swiper {
    overflow: visible !important;
}